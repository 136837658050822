import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import NoteIcon from '../../assets/note.svg';
import { LessonPropertyTabProvider } from '../../contexts/LessonPropertyTabContext';
import { useStore } from '../../contexts/StoreContext';
import { useFinishLessonSingleCustomer } from '../../hooks/useFinishLessonSingleCustomer';
import Loader from '../base/display/Loader';
import Page from '../base/Page';
import { Routes } from '../const/Routes';
import { colors, profileBorders } from '../const/Styles';
import CoachProfile from './display/CoachProfile';
import CustomerBasicProfile from './display/CustomerBasicProfile';
import CustomerDetailedProfile from './display/CustomerDetailedProfile';
import LessonContent from './display/LessonContent';
import LessonCustomers from './display/LessonCustomers';
import Questionnaire from './display/Questionnaire';
import LessonPropertyNavigation from './navigation/LessonPropertyNavigation';
import TodayLessonCloser from './navigation/TodayLessonCloser';
import CloseLessonDialog from './surface/CloseLessonDialog';

const Container = styled.section`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 190px 1fr;
    grid-template-columns: 200px 1fr;
    overflow: hidden;
`;

const LessonPropertyContainer = styled.section`
    grid-column: 1 / 3;
    overflow: hidden;
    border-top 2px solid ${colors.gray};
    display: flex;
    flex-direction: column;
`;

const LessonStartPage: React.FC = () => {
    const { lessonStore, recordingStore, tempoStore } = useStore();
    const { selectedCustomersId, viewedCustomer } = lessonStore;
    const activeColor = useMemo(() => {
        const index = selectedCustomersId.findIndex(
            (p) => p === viewedCustomer.id,
        );
        return profileBorders[(index + 1) % 5];
    }, [selectedCustomersId, viewedCustomer]);

    const [isProcessing, execute] = useFinishLessonSingleCustomer();
    const [isDialogOpended, setIsDialogOpended] = useState(false);
    const handleCloseButtonClick = useCallback(() => {
        setIsDialogOpended(true);
    }, []);

    const history = useHistory();
    const handleCloseDialog = async (agreed: boolean): Promise<void> => {
        setIsDialogOpended(false);

        if (agreed === true) {
            try {
                await execute()
                recordingStore.clearRegisteredVideosByCustomerId(
                    `${lessonStore.viewedCustomer.id}`,
                );

                if (lessonStore.selectedCustomersId.length === 0) {
                    recordingStore.clearVideos()
                    history.push(Routes.LESSON_LIST);
                } else {
                    lessonStore.setViewedCustomer();
                }

                window.alert('登録が完了しました。');
            } catch (error: unknown) {
                console.error(error);
                window.alert('登録できませんでした。\nインターネット回線が安定している場所で再登録いただくようお願いいたします。');
                throw error;
            }
        }
    };

    useAsync(async () => {
        await tempoStore.loadFavoriteMovies(lessonStore.viewedCustomer.id);
    }, [viewedCustomer.id]);

    return (
        <Page headerIcon={NoteIcon} title='レッスンノート'>
            <LessonPropertyTabProvider>
                <Container>
                    <CoachProfile showProfile={false} />
                    <LessonCustomers />
                    <LessonPropertyContainer>
                        <LessonPropertyNavigation activeColor={activeColor} />
                        <LessonContent
                            customer={viewedCustomer}
                            activeColor={activeColor}
                        ></LessonContent>
                        <CustomerBasicProfile customer={viewedCustomer} />
                        <CustomerDetailedProfile
                            customerId={viewedCustomer.id}
                        />
                        <Questionnaire
                            customerId={viewedCustomer.id}
                        ></Questionnaire>
                    </LessonPropertyContainer>
                    <LessonPropertyContainer>
                        <TodayLessonCloser
                            onClick={handleCloseButtonClick}
                            isEnabled={
                                lessonStore.getLessonComment(
                                    `${viewedCustomer.id}`,
                                ).length > 0
                            }
                        />
                    </LessonPropertyContainer>
                </Container>
            </LessonPropertyTabProvider>
            <CloseLessonDialog
                isOpended={isDialogOpended}
                onClose={handleCloseDialog}
            />
            <Loader isOpen={isProcessing} />
        </Page>
    );
};

export default observer(LessonStartPage);
