import {
    Card, Checkbox, Container, createStyles, FormControl, FormControlLabel, Grid, makeStyles, Radio,
    RadioGroup, Typography
} from '@material-ui/core';
import React, { ChangeEvent, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import clsx from 'clsx';
import dateFormat from 'date-fns/format';
import { useStore } from '../../contexts/StoreContext';
import { FormReducerAction } from '../../hooks/useForm';
import useSeparateDateInput from '../../hooks/useSeparateDateInput';
import Api, { ErrorMessages, StaffFormType } from '../../utils/Api';
import { emptyToNull } from '../../utils/strings';
import ErrorMessage from '../base/feedback/ErrorMessage';
import MultiFieldErrorMessage from '../base/feedback/MultiFieldErrorMessage';
import { GenderKeyType } from '../const/Gender';
import { StaffType } from '../const/StaffType';
import { colors } from '../const/Styles';
import OutlinedInputText from '../form/OutlinedInputText';
import NativeDateInput from './input/NativeDateInput';

const StyledInputText = OutlinedInputText;

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            marginTop: 15,
        },
        root: {
            paddingRight: '74px',
            paddingLeft: '74px',
        },
        container: {
            width: '100%',
            margin: 'auto',
            padding: 0,
        },
        formTitle: {
            display: 'flex',
            alignItems: 'center',
            width: 174,
        },
        formSet: {
            display: 'flex',
            paddingTop: 20,
            paddingBottom: 30,
            borderBottom: `1px solid ${colors.lightGray}`,
        },
        formSet__NoBorder: {
            display: 'flex',
            paddingTop: 20,
            paddingBottom: 30,
        },

        inputBox: {
            display: 'flex',
        },
        deleteLabelSpace: {
            '& > div > div.MuiBox-root': {
                width: 0,
            },
        },
        spanText: {
            marginTop: 'auto',
            marginBottom: 'auto',
            marginRight: 10,
        },
        checkColor: {
            '&.Mui-checked': {
                color: `${colors.footerIconSelected}`,
            },
        },
        actionRow: {
            justifyContent: 'space-between',
            width: '100%'
        }
    }),
);

const ActionButton = styled.button`
    display: block;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: ${colors.black};
    box-sizing: border-box;
    padding: 5px 15px;
    border: none;
    outline: none;
    background-color: ${colors.lighterGray};
    border-radius: 4px;
`;

interface Props {
    data: StaffFormType;
    dispatcher: React.Dispatch<FormReducerAction<StaffFormType>>;
    errorProps: ErrorMessages | undefined;
    onDelete: () => void
}

const StaffUpdateCard: React.FC<Props> = ({ data, dispatcher, errorProps, onDelete }: Props) => {
    const classes = useStyles();

    const handleChangeType = (event: ChangeEvent<HTMLInputElement>) => {
        dispatcher({ value: { type: event.currentTarget.value as StaffType } })
    }
    const [date, dateDispacher] = useSeparateDateInput(data.date_of_birth);

    // 姓名
    const handleChangeNameSei = (event: ChangeEvent<HTMLInputElement>) => {
        dispatcher({ value: { name_sei: event.currentTarget.value } });
    }
    const handleChangeNameMei = (event: ChangeEvent<HTMLInputElement>) => {
        dispatcher({ value: { name_mei: event.currentTarget.value } });
    }

    // 姓名かな
    const handleChangeNameSeiKana = (event: ChangeEvent<HTMLInputElement>) => {
        dispatcher({ value: { name_sei_kana: event.currentTarget.value } });
    }
    const handleChangeNameMeiKana = (event: ChangeEvent<HTMLInputElement>) => {
        dispatcher({ value: { name_mei_kana: event.currentTarget.value } });
    }

    // 生年月日
    const handleChangeBirthYear = (event: ChangeEvent<HTMLInputElement>) => {
        dateDispacher({ value: { year: event.currentTarget.valueAsNumber } });
    }
    const handleChangeBirthMonth = (event: ChangeEvent<HTMLInputElement>) => {
        dateDispacher({ value: { month: event.currentTarget.valueAsNumber } });
    }
    const handleChangeBirthDay = (event: ChangeEvent<HTMLInputElement>) => {
        dateDispacher({ value: { day: event.currentTarget.valueAsNumber } });
    }

    // 性別
    const handleChangeGender = (event: ChangeEvent<HTMLInputElement>) => {
        dispatcher({ value: { sex: event.currentTarget.value as GenderKeyType } });
    }

    // 資格系
    const handleChangeCertificateName1 = (event: ChangeEvent<HTMLInputElement>) => {
        dispatcher({ value: { certification1_name: event.currentTarget.value } });
    }
    const handleChangeCertificateDate1 = (changeValue: string) => {
        dispatcher({ value: { certification1_date: emptyToNull(changeValue) } });
    }
    const handleChangeCertificateName2 = (event: ChangeEvent<HTMLInputElement>) => {
        dispatcher({ value: { certification2_name: event.currentTarget.value } });
    }
    const handleChangeCertificateDate2 = (changeValue: string) => {
        dispatcher({ value: { certification2_date: emptyToNull(changeValue) } });
    }
    const handleChangeCertificateName3 = (event: ChangeEvent<HTMLInputElement>) => {
        dispatcher({ value: { certification3_name: event.currentTarget.value } });
    }
    const handleChangeCertificateDate3 = (changeValue: string) => {
        dispatcher({ value: { certification3_date: emptyToNull(changeValue) } });
    }

    // 削除フラグ + アクティブ
    // TODO: 意味が重複しているのでどちらかに整合する必要あり
    const handleChangeIsDeletedAndActive = (event: ChangeEvent<HTMLInputElement>) => {
        dispatcher({
            value: {
                is_deleted: !event.currentTarget.checked,
                is_active: event.currentTarget.checked,
            }
        });
    }

    useEffect(() => {
        const { isValid, date: dateInstance } = date;
        if (isValid && dateInstance) {
            dispatcher({ value: { date_of_birth: dateFormat(dateInstance, 'yyyy-MM-dd') } });
        }
    }, [date, dispatcher])

    const { tempoStore } = useStore();
    const handleClickDeleteButton = useCallback(async () => {
        if (!confirm('このメンバーを削除してもよろしいですか？')) {
            return;
        }

        if (!confirm('削除するとすべてのデータが削除されます。\nそれでも削除しますか？')) {
            return;
        }

        try {
            // 物理削除
            await Api.deleteStaff(data.id)
            await tempoStore.fetchAllStaffs();
            alert('メンバーを削除しました');
            onDelete();
        } catch (error: unknown) {
            alert('メンバーの削除に失敗しました。\n再度時間をおいて実行してください。');
            console.error(error);
            throw error;
        }
    }, [data.id, tempoStore, onDelete]);

    return (
        <Card>
            <Card className={classes.root}>
                <Container className={classes.container}>
                    <Grid container>
                        <Grid container className={classes.formSet}>
                            <Grid className={classes.formTitle}>
                                <Typography>スタッフ種別</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-label='position'
                                        name='position'
                                        defaultValue='unknown'
                                        value={data.type}
                                        onChange={handleChangeType}
                                    >
                                        <FormControlLabel
                                            label='コーチ'
                                            value='coach'
                                            control={
                                                <Radio
                                                    className={
                                                        classes.checkColor
                                                    }
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label='事務員'
                                            value='clerk'
                                            control={
                                                <Radio
                                                    className={
                                                        classes.checkColor
                                                    }
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <ErrorMessage field='type' errors={errorProps} />
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>氏名</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                className={clsx(
                                    classes.inputBox,
                                    classes.deleteLabelSpace,
                                )}
                            >
                                <StyledInputText
                                    id='name_sei'
                                    value={data.name_sei}
                                    onChange={handleChangeNameSei}
                                    style={{ width: '150px' }}
                                    placeholder='山田'
                                    label={'名字'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <StyledInputText
                                    id='name_mei'
                                    value={data.name_mei}
                                    onChange={handleChangeNameMei}
                                    style={{ width: '150px' }}
                                    placeholder='太郎'
                                    label={'名前'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <MultiFieldErrorMessage fields={['name_mei', 'name_sei']} errors={errorProps} />
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>ふりがな</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                className={
                                    classes.inputBox +
                                    ' ' +
                                    classes.deleteLabelSpace
                                }
                            >
                                <StyledInputText
                                    id='name_sei_kana'
                                    value={data.name_sei_kana}
                                    onChange={handleChangeNameSeiKana}
                                    style={{ width: '150px' }}
                                    placeholder={'やまだ'}
                                    label={'みょうじ'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <StyledInputText
                                    id='name_mei_kana'
                                    value={data.name_mei_kana}
                                    onChange={handleChangeNameMeiKana}
                                    style={{ width: '150px' }}
                                    placeholder={'たろう'}
                                    label={'なまえ'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <MultiFieldErrorMessage fields={['name_mei_kana', 'name_sei_kana']} errors={errorProps} />
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>生年月日</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.inputBox}>
                                <StyledInputText
                                    id='birth_year'
                                    value={date.year}
                                    onChange={handleChangeBirthYear}
                                    type='number'
                                    style={{ width: '80px' }}
                                    placeholder='1976'
                                />
                                <Typography className={classes.spanText}>
                                    年
                                </Typography>
                                <StyledInputText
                                    id='birth_month'
                                    value={date.month}
                                    onChange={handleChangeBirthMonth}
                                    type='number'
                                    style={{ width: '80px' }}
                                    placeholder='6'
                                />
                                <Typography className={classes.spanText}>
                                    月
                                </Typography>
                                <StyledInputText
                                    id='birth_day'
                                    value={date.day}
                                    onChange={handleChangeBirthDay}
                                    type='number'
                                    style={{ width: '80px' }}
                                    placeholder='24'
                                />
                                <Typography className={classes.spanText}>
                                    日
                                </Typography>
                            </Grid>
                            <ErrorMessage field='date_of_birth' errors={errorProps} />
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>性別</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-label='position'
                                        name='position'
                                        defaultValue='unknown'
                                        value={data.sex}
                                        onChange={handleChangeGender}
                                    >
                                        <FormControlLabel
                                            label='男性'
                                            value='male'
                                            control={
                                                <Radio
                                                    className={
                                                        classes.checkColor
                                                    }
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label='女性'
                                            value='female'
                                            control={
                                                <Radio
                                                    className={
                                                        classes.checkColor
                                                    }
                                                />
                                            }
                                        />
                                        <FormControlLabel
                                            label='未設定'
                                            value='unknown'
                                            control={
                                                <Radio
                                                    className={
                                                        classes.checkColor
                                                    }
                                                />
                                            }
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet__NoBorder}>
                            <Grid item className={classes.formTitle}>
                                <Typography>資格 / 資格取得年月日</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.inputBox}>
                                <StyledInputText
                                    id='certification1'
                                    value={data.certification1_name}
                                    onChange={handleChangeCertificateName1}
                                    style={{ width: '197px' }}
                                />
                                <NativeDateInput
                                    id='certification1-date'
                                    value={data.certification1_date}
                                    onChange={handleChangeCertificateDate1}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet__NoBorder}>
                            <Grid item className={classes.formTitle} />
                            <Grid item xs={6} className={classes.inputBox}>
                                <StyledInputText
                                    id='certification2'
                                    value={data.certification2_name}
                                    onChange={handleChangeCertificateName2}
                                    style={{ width: '197px' }}
                                />
                                <NativeDateInput
                                    id='certification2-date'
                                    value={data.certification2_date}
                                    onChange={handleChangeCertificateDate2}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet__NoBorder}>
                            <Grid item className={classes.formTitle} />
                            <Grid item xs={6} className={classes.inputBox}>
                                <StyledInputText
                                    id='certification3'
                                    value={data.certification3_name}
                                    onChange={handleChangeCertificateName3}
                                    style={{ width: '197px' }}
                                />
                                <NativeDateInput
                                    id='certification3-date'
                                    value={data.certification3_date}
                                    onChange={handleChangeCertificateDate3}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet__NoBorder}>
                            <Grid item className={classes.formTitle}>&nbsp;</Grid>
                            <Grid container xs justify='space-between' alignItems='center' direction='row' wrap='nowrap'>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={data.is_active}
                                                onChange={handleChangeIsDeletedAndActive}
                                            />
                                        }
                                        label='入社'
                                    />
                                </Grid>
                                <Grid item>
                                    <ActionButton onClick={handleClickDeleteButton}>即時削除</ActionButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Card>
        </Card>
    );
}

export default StaffUpdateCard;
