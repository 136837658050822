import { Button, Card, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useCallback, useState } from 'react';

import axios, { AxiosError } from 'axios';
import NotSelectedImg from '../../assets/notselected.png';
import { StaffEntity } from '../../components/@types/mysqlEntities';
import TempoStore from '../../store/TempoStore';
import Api, { ErrorMessages, ErrorResponse } from '../../utils/Api';
import ErrorMessage from '../base/feedback/ErrorMessage';
import { GenderKeyType } from '../const/Gender';
import { StaffType } from '../const/StaffType';
import { colors } from '../const/Styles';
import CloseModalButton from './input/CloseModalButton';
import StaffRegisterCard from './StaffRegisterCard';
import StaffRegisterImageForm from './StaffRegisterImageForm';

const useStyles = makeStyles(() =>
    createStyles({
        registerButtonBox: {
            display: 'flex',
            justifyContent: 'center',
            margin: 10,
        },
        registerButton: {
            display: 'flex',
            justifyContent: 'center',
            margin: 10,
            width: 445,
            backgroundColor: `${colors.footerIconSelected}`,
            color: `${colors.black}`,
            fontWeight: 'bold',
            fontSize: 17,
        },
        title: {
            color: `${colors.footerIconSelected}`,
            fontSize: 37,
            fontWeight: 'bold',
        },
        titleBox: {
            display: 'flex',
            width: '100%',
            margin: '45px 22px',
        },
        titleWrap: {
            width: '75%',
        },
        closeBtnBox: {
            position: 'absolute',
            top: 20,
            right: 40,
        },
        main: {
            position: 'relative',
            width: '100%',
            minWidth: 650,
            border: `1px solid ${colors.black}`,
            margin: 'auto',
        },
        profileImgBox: {
            width: '25%',
        },
    }),
);

export interface CreateStaffFormLocationState {
    staff?: StaffEntity;
}

type Props = {
    isOpended: boolean;
    onClose: () => void;
};

const CreateStaffForm: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const values = {
        type: '',
        name_sei: '',
        name_mei: '',
        name_sei_kana: '',
        name_mei_kana: '',
        date_of_birth: '',
        sex: 'unknown',
        certification1_name: '',
        certification1_date: '',
        certification2_name: '',
        certification2_date: '',
        certification3_name: '',
        certification3_date: '',
    };

    const [type, setType] = useState<string>(values.type);
    const [profilePicture, setProfilePicture] = useState<string>();
    const [imageFile, setImageFile] = useState<File>();
    const [nameSei, setNameSei] = useState<string>(values.name_sei);
    const [nameMei, setNameMei] = useState<string>(values.name_mei);
    const [nameSeiKana, setNameSeiKana] = useState<string>(
        values.name_sei_kana,
    );
    const [nameMeiKana, setNameMeiKana] = useState<string>(
        values.name_mei_kana,
    );
    const [dateOfBirth, setDateOfBirth] = useState<string>(
        values.date_of_birth,
    );
    const [sex, setSex] = useState<string>(values.sex);
    const [certification1Name, setCertification1Name] = useState<string>(
        values.certification1_name,
    );
    const [certification1Date, setCertification1Date] = useState<string>(
        values.certification1_date,
    );
    const [certification2Name, setCertification2Name] = useState<string>(
        values.certification2_name,
    );
    const [certification2Date, setCertification2Date] = useState<string>(
        values.certification2_date,
    );
    const [certification3Name, setCertification3Name] = useState<string>(
        values.certification3_name,
    );
    const [certification3Date, setCertification3Date] = useState<string>(
        values.certification3_date,
    );

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const imgFile = event.target.files[0];
        setImageFile(imgFile);
        setProfilePicture(window.URL.createObjectURL(imgFile));
    };

    const [errorProps, setErrorProps] =
        React.useState<ErrorMessages>();

    const onClickRegister = useCallback(async () => {
        let filename: string;
        try {
            const response = await Api.registerStaff(
                type,
                nameSei,
                nameMei,
                nameSeiKana,
                nameMeiKana,
                dateOfBirth,
                sex,
                certification1Name,
                certification1Date,
                certification2Name,
                certification2Date,
                certification3Name,
                certification3Date,
                imageFile?.name || '',
            );
            filename = response.filename;

            TempoStore.appendStaff({
                id: response.id,
                tempo_id: response.tempo_id,
                type: type as StaffType,
                sex: sex as GenderKeyType,
                profile_picture_filename: response.filename,
                profile_picture_filepath: '',
                created_at: response.created_at,
                name_sei: nameSei,
                name_mei: nameMei,
                name_sei_kana: nameSeiKana,
                name_mei_kana: nameMeiKana,
                date_of_birth: dateOfBirth,
                certification1_name: certification1Name,
                certification1_date: certification1Date,
                certification2_name: certification2Name,
                certification2_date: certification2Date,
                certification3_name: certification3Name,
                certification3_date: certification3Date,
                is_active: true,
                s3Path: `${import.meta.env.BASE_URL}sample_account.png`,
                updated_at: (new Date()).toString(),
                deleted_at: null,
            });
        } catch (err) {
            if (!axios.isAxiosError(err)) {
                throw err;
            }
            const { response } = err as AxiosError<ErrorResponse>;
            setErrorProps(response?.data.message ?? {});
            return;
        }
        props.onClose();

        try {
            if (imageFile) {
                const presignedUrl =
                    await Api.getS3SignedUrlForUploadStaffPicture(filename);
                await Api.uploadToS3(presignedUrl.presignedUrl, imageFile);
            }
            await TempoStore.fetchAllStaffs();
        } catch (err) {
            // TODO 現状の実装だと画像アップロードが失敗した際にユーザがそれを認識できないので、フォームのバリデーション同様エラーメッセージ出すなどしたい
            if (axios.isAxiosError(err)) {
                console.log(err);
            }
            throw err;
        }
    }, [props, type, nameSei, nameMei, nameSeiKana, nameMeiKana, dateOfBirth, sex, certification1Name, certification1Date, certification2Name, certification2Date, certification3Name, certification3Date, imageFile]);

    return (
        <Card className={classes.main}>
            <Grid container className={classes.titleBox}>
                <Grid className={classes.profileImgBox}>
                    <StaffRegisterImageForm
                        img={profilePicture ?? `${NotSelectedImg}`}
                        handleFileChange={handleFileChange}
                    />
                    <ErrorMessage field='profile_picture_filename' errors={errorProps} />
                </Grid>
                <div className={classes.titleWrap}>
                    <p className={classes.title}>コーチ新規登録</p>
                </div>
                <CloseModalButton
                    isOpended={props.isOpended}
                    onClose={props.onClose}
                />
                <Grid item xs={10}></Grid>
            </Grid>
            <StaffRegisterCard
                type={type}
                nameSei={nameSei}
                nameMei={nameMei}
                nameSeiKana={nameSeiKana}
                nameMeiKana={nameMeiKana}
                sex={sex}
                certification1Name={certification1Name}
                certification1Date={certification1Date}
                certification2Name={certification2Name}
                certification2Date={certification2Date}
                certification3Name={certification3Name}
                certification3Date={certification3Date}
                setType={setType}
                setNameSei={setNameSei}
                setNameMei={setNameMei}
                setNameSeiKana={setNameSeiKana}
                setNameMeiKana={setNameMeiKana}
                setDateOfBirth={setDateOfBirth}
                setSex={setSex}
                setCertification1Name={setCertification1Name}
                setCertification1Date={setCertification1Date}
                setCertification2Name={setCertification2Name}
                setCertification2Date={setCertification2Date}
                setCertification3Name={setCertification3Name}
                setCertification3Date={setCertification3Date}
                errorProps={errorProps}
            />
            <div className={classes.registerButtonBox}>
                <Button
                    onClick={onClickRegister}
                    className={classes.registerButton}
                >
                    登録
                </Button>
            </div>
        </Card>
    );
}

export default CreateStaffForm;
