import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect } from 'react-router';
import { BrowserRouter, Route, RouteProps, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useAsync } from 'react-use';

import AuthStore from '../store/AuthStore';
import SiteCommonTheme from '../style/SiteCommonTheme';
import Cover from '../components/Cover';
import Login from '../components/Login';
import PasswordReset from '../components/PasswordReset';
import { LessonStateContextProvider } from '../contexts/LessonStateContext';
import { StoreProvider } from '../contexts/StoreContext';
import Routes from './Routes';

import * as Sentry from '@sentry/react';

const GlobalStyle = withStyles({
    '@global': {
        '*': {
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        },
        'html, body': {
            margin: 0,
            overscrollBehaviorY: 'none',
            background: '#F0F0F6',
        },
    },
})(() => null);

const PrivateRoute: React.FC<RouteProps> = observer((props) => {
    return AuthStore.isLoggedIn ? (
        <Route {...props} />
    ) : (
        <Route path='/'>
            <Redirect to='/login' />
        </Route>
    );
});

const AppContainer: React.FC = observer(() => {
    useAsync(async () => {
        await AuthStore.checkLogin();
    });

    // 古いSafariをサポートするのでJSのハックを入れる
    const fitViewHeight = () => {
        const viewPortHeight = `${window.innerHeight}px`;
        document.getElementsByTagName('html')[0].style.height = viewPortHeight;
        document.getElementsByTagName('body')[0].style.height = viewPortHeight;
        const rootDom = document.getElementById('root');
        if (rootDom) {
            rootDom.style.height = viewPortHeight;
        }
    }

    window.addEventListener('resize', fitViewHeight);

    useEffect(() => {
        window.addEventListener('resize', fitViewHeight);
        fitViewHeight()

        return () => {
            window.removeEventListener('resize', fitViewHeight);
        }
    }, [])

    // Sentry用
    useEffect(() => {
        if (!AuthStore.isLoggedIn) {
            return;
        }
        Sentry?.setUser({
            id: AuthStore.tempoId,
            username: AuthStore.name ?? '-',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AuthStore.isLoggedIn]);

    return (
        <ThemeProvider theme={SiteCommonTheme}>
            <GlobalStyle />
            <BrowserRouter>
                {/** TODO: ローディング終了を検知してないので検知するようにする(多分、ログインが遅くなった場合は暫くログイン画面が出ることになる) */}
                <Switch>
                    <Route exact path='/cover'>
                        <Cover />
                    </Route>
                    <Route exact path='/login'>
                        {AuthStore.isLoggedIn ? <Redirect to='/' /> : <Login />}
                    </Route>
                    <Route exact path='/password/reset'>
                        <PasswordReset />
                    </Route>
                    <PrivateRoute>
                        <StoreProvider>
                            <LessonStateContextProvider>
                                <Routes />
                            </LessonStateContextProvider>
                        </StoreProvider>
                    </PrivateRoute>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
});

export default AppContainer;
