import { useState } from 'react';
import { useStore } from '../contexts/StoreContext';
import Api from '../utils/Api';
import { LessonDisplay } from '../utils/Lesson';

export function useFinishLessonSingleCustomer(): [boolean, () => Promise<void>] {
    const { recordingStore, tempoStore, lessonStore } = useStore();
    const [isProcessing, setIsProsessing] = useState(false);
    const callback = async (): Promise<void> => {
        setIsProsessing(true)
        try {
            const { viewedCustomer } = lessonStore;
            const { selectedCoach } = tempoStore;
            if (!selectedCoach) {
                throw Error('no coarch selected');
            }
            // レッスンの登録
            const { id, lesson_master_id } = viewedCustomer;
            const comment = lessonStore.getLessonComment(String(id));
            const record = lessonStore.getLessonRecord(String(id)) ?? [];
            const rawLesson = await Api.createLesson(
                String(id),
                lesson_master_id,
                String(selectedCoach.id),
                comment,
                record,
            );
            const newLesson = new LessonDisplay(rawLesson);

            const videoData = recordingStore.getRegisteredVideo(String(id));

            // 動画データがあれば紐づけて登録する
            if (videoData) {
                const uploadJobs = videoData.map(async ({ video, club, yard, toolImage, isBestSwing }) => {
                    if (!video) {
                        // 動画がなければ何もしない
                        return Promise.resolve();
                    }

                    const responseMovie = await Api.createCustomerVideo(
                        String(id),
                        lesson_master_id,
                        newLesson.lesson_id,
                        club || '',
                        yard || '',
                        'mp4',
                        isBestSwing,
                    );
                    const presignedUrl = await Api.getS3SignedUrlForUploadMovie(
                        responseMovie.filename,
                    );
                    await Api.uploadToS3(
                        presignedUrl.presignedUrl,
                        new File([video], 'test'),
                    );

                    if (toolImage) {
                        const { tool_image_filename } = await Api.updateToolImage(
                            responseMovie.id,
                            responseMovie.customer_id_or_staff_id,
                            responseMovie.created_at,
                            responseMovie.filename,
                        );
                        const { presignedUrl } =
                            await Api.getS3SignedUrlForUploadToolImage(
                                tool_image_filename,
                            );
                        await Api.uploadToS3(presignedUrl, toolImage);
                    }
                });
                // FIXME: ここでrejectされた際は何故か捕捉できないので自前スローしているが、Axiosのインターセプター等の共通処理に寄せたい
                await Promise
                    .all(uploadJobs)
                    .catch(error => {
                        throw error;
                    });
            }

            lessonStore.initTodaysLesson(String(id));
            lessonStore.unselectCustomer(id);
        } finally {
            setIsProsessing(false);
        }
    }

    return [isProcessing, callback]
}
