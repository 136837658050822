import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import React, {
    ChangeEvent,
    Dispatch,
    useContext,
    useMemo,
    useState,
} from 'react';

import styled from 'styled-components';
import leftArrow from '../../../assets/left_arrow.svg';
import rightArrow from '../../../assets/right_arrow.svg';
import { CustomerRegisterPropertyTabContext } from '../../../contexts/CustomerRegisterPropertyTabContext';
import { FormReducerAction } from '../../../hooks/useForm';
import { ErrorMessages } from '../../../utils/Api';
import { CustomerDetailInfomationForm } from '../../@types/request';
import ErrorMessage from '../../base/feedback/ErrorMessage';
import {
    CUSTOMER_REGIST_BASIC_PROFILE,
    CUSTOMER_REGIST_DETAIL_PROFILE,
    CUSTOMER_REGIST_QUESTIONNAIRE,
} from '../../const/RegisterProperties';
import { colors } from '../../const/Styles';
import DominantButton from '../../form/DominantButton';
import OutlinedInputText from '../../form/OutlinedInputText';
import ProjectileSelector from '../input/ProjectileSelector';

const StyledInputText = OutlinedInputText;

const useStyles = makeStyles(() =>
    createStyles({
        careerInfo: {
            width: '100%',
        },
        skillInfo: {
            marginTop: 50,
            width: '100%',
        },
        formSet: {
            display: 'flex',
            borderBottom: `1px solid ${colors.lightGray}`,
            paddingTop: 5,
            paddingBottom: 5,
            fontSize: 13,
        },
        inputBox: {
            display: 'flex',
        },
        formInput: {
            flexGrow: 1,
        },
        modalTrajectory: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        modalCard: {
            padding: 8,
        },
        modalButton: {
            marginLeft: 'auto',
            marginTop: 8,
            display: 'flex',
        },
        formTitle: {
            display: 'flex',
            alignItems: 'center',
            width: 160,
        },
        formInputFull: {
            width: 350,
        },
        rightArrowImgBox: {
            position: 'absolute',
            right: '-75px',
            bottom: '50%',
        },
        leftArrowImgBox: {
            position: 'absolute',
            left: '-75px',
            bottom: '50%',
        },
        projectile_error: {
            width: '232px',
            marginLeft: '160px',
            textAlign: 'center',
        },
    }),
);

interface Props {
    detailInfo: CustomerDetailInfomationForm;
    detailInfoDispatch: Dispatch<
        FormReducerAction<CustomerDetailInfomationForm>
    >;
    errorProps: ErrorMessages | undefined;
}

type ContainerProps = {
    visible: boolean;
};
const ContainerBox = styled.section`
    width: 570px;
    height: 580px;
    overflow-y: scroll;
    margin-top: 27px;
    position: relative;
    backgroundcolor: ${colors.white};
`;

const Wrapper = styled.section<ContainerProps>`
    display: ${({ visible }) => (visible === true ? 'block' : 'none')};
    position: relative;
`;

const CustomerRegisterDetail: React.FC<Props> = ({
    detailInfo,
    detailInfoDispatch,
    errorProps,
}) => {
    const classes = useStyles();
    // 球筋の選択肢は1~9までなのでそれに対応するインデックスの配列を用意
    const projectileOptions = [...Array(9).keys()].map((i: number) => i + 1);

    const handleProjectile = (
        event: React.MouseEvent<HTMLElement>,
        newProjectile: string,
    ) => {
        detailInfoDispatch({ value: { projectile: String(newProjectile) } });
    };

    const handleChangeProjectile = (value: string) => {
        detailInfoDispatch({ value: { projectile: value } });
    };

    const handleInputStringValue = (
        key: keyof CustomerDetailInfomationForm,
    ) => {
        return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const { value } = event.currentTarget;
            const valueMap = new Map<string, string>();
            valueMap.set(key, value);
            detailInfoDispatch({ value: Object.fromEntries(valueMap) });
        };
    };

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const { selected, onChange } = useContext(
        CustomerRegisterPropertyTabContext,
    );
    const visible = useMemo(() => {
        return CUSTOMER_REGIST_DETAIL_PROFILE === selected;
    }, [selected]);

    const moveBasicPage = () => {
        if (onChange) {
            onChange(CUSTOMER_REGIST_BASIC_PROFILE);
        }
    };

    const moveQuestionnairePage = () => {
        if (onChange) {
            onChange(CUSTOMER_REGIST_QUESTIONNAIRE);
        }
    };

    return (
        <>
            <Wrapper visible={visible}>
                <ContainerBox>
                    <Container className={classes.careerInfo}>
                        <Divider light />

                        <Grid container className={classes.formSet}>
                            <Grid className={classes.formTitle}>
                                <Typography>ヘッドスピード(m/s)</Typography>
                            </Grid>

                            <Grid>
                                <ErrorMessage
                                    field='head_speed'
                                    errors={errorProps}
                                />
                                <StyledInputText
                                    value={detailInfo.head_speed}
                                    onChange={handleInputStringValue(
                                        'head_speed',
                                    )}
                                    id='head_speed'
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>飛距離</Typography>
                            </Grid>
                            <Grid item>
                                <ErrorMessage
                                    field='distance'
                                    errors={errorProps}
                                />
                                <StyledInputText
                                    value={detailInfo.distance}
                                    onChange={handleInputStringValue(
                                        'distance',
                                    )}
                                    id='distance'
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <div className={classes.projectile_error}>
                                <ErrorMessage
                                    field='projectile'
                                    errors={errorProps}
                                />
                            </div>

                            <ProjectileSelector
                                selected={detailInfo.projectile}
                                onChange={handleChangeProjectile}
                            />
                        </Grid>

                        <Modal open={open} className={classes.modalTrajectory}>
                            <Card className={classes.modalCard}>
                                <ToggleButtonGroup
                                    value={detailInfo.projectile}
                                    exclusive
                                    onChange={handleProjectile}
                                    aria-label='projectile'
                                >
                                    {projectileOptions.map((index: number) => {
                                        const projectileIndex =
                                            index.toString();
                                        return (
                                            <ToggleButton
                                                key={projectileIndex}
                                                value={`${projectileIndex}型`}
                                                aria-label={`${projectileIndex}型`}
                                            >
                                                <img
                                                    width='100%'
                                                    src={`${import.meta.env.BASE_URL}projectile/${projectileIndex}_tamasuji.png`}
                                                    title={`${projectileIndex}型`}
                                                />
                                            </ToggleButton>
                                        );
                                    })}
                                </ToggleButtonGroup>
                                <DominantButton
                                    className={classes.modalButton}
                                    onClick={handleClose}
                                >
                                    確定
                                </DominantButton>
                            </Card>
                        </Modal>

                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>ベストスコア</Typography>
                            </Grid>
                            <Grid item>
                                <ErrorMessage
                                    field='best_score'
                                    errors={errorProps}
                                />
                                <StyledInputText
                                    value={detailInfo.best_score}
                                    onChange={handleInputStringValue(
                                        'best_score',
                                    )}
                                    id='score_best'
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>平均スコア</Typography>
                            </Grid>
                            <Grid item>
                                <ErrorMessage
                                    field='avg_score'
                                    errors={errorProps}
                                />
                                <StyledInputText
                                    value={detailInfo.avg_score}
                                    onChange={handleInputStringValue(
                                        'avg_score',
                                    )}
                                    id='score_average'
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>年間ラウンド数(回)</Typography>
                            </Grid>
                            <Grid item>
                                <ErrorMessage
                                    field='rounds_per_year'
                                    errors={errorProps}
                                />
                                <StyledInputText
                                    value={detailInfo.rounds_per_year}
                                    onChange={handleInputStringValue(
                                        'rounds_per_year',
                                    )}
                                    id='rounds_per_year'
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>練習回数</Typography>
                            </Grid>
                            <Grid item>
                                <ErrorMessage
                                    field='total_practice'
                                    errors={errorProps}
                                />
                                <StyledInputText
                                    value={detailInfo.total_practice}
                                    onChange={handleInputStringValue(
                                        'total_practice',
                                    )}
                                    id='total_practices'
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>得意クラブ</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <ErrorMessage
                                    field='club_good_at'
                                    errors={errorProps}
                                />
                                <StyledInputText
                                    value={detailInfo.club_good_at}
                                    onChange={handleInputStringValue(
                                        'club_good_at',
                                    )}
                                    id='club_good_at'
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>好きなクラブ</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <ErrorMessage
                                    field='club_favorite'
                                    errors={errorProps}
                                />
                                <StyledInputText
                                    value={detailInfo.club_favorite}
                                    onChange={handleInputStringValue(
                                        'club_favorite',
                                    )}
                                    id='club_fav'
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>ゴルフ歴(年)</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <ErrorMessage
                                    field='years_golf'
                                    errors={errorProps}
                                />
                                <StyledInputText
                                    value={detailInfo.years_golf}
                                    onChange={handleInputStringValue(
                                        'years_golf',
                                    )}
                                    id='years_golf'
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>スポーツ歴</Typography>
                            </Grid>
                            <Grid item className={classes.formInputFull}>
                                <ErrorMessage
                                    field='years_sport'
                                    errors={errorProps}
                                />
                                <StyledInputText
                                    value={detailInfo.years_sport}
                                    onChange={handleInputStringValue(
                                        'years_sport',
                                    )}
                                    id='years_sport'
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>ホームコース</Typography>
                            </Grid>
                            <Grid item className={classes.formInputFull}>
                                <ErrorMessage
                                    field='home_course'
                                    errors={errorProps}
                                />
                                <StyledInputText
                                    value={detailInfo.home_course}
                                    onChange={handleInputStringValue(
                                        'home_course',
                                    )}
                                    id='home_course'
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.formSet}>
                            <Grid item className={classes.formTitle}>
                                <Typography>メモ(非公開)</Typography>
                            </Grid>
                            <Grid item className={classes.formInputFull}>
                                <ErrorMessage
                                    field='memo'
                                    errors={errorProps}
                                />
                                <StyledInputText
                                    value={detailInfo.memo}
                                    onChange={handleInputStringValue('memo')}
                                    id='memo'
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </ContainerBox>
                <div className={classes.rightArrowImgBox}>
                    <img
                        src={rightArrow}
                        onClick={moveQuestionnairePage}
                        alt=''
                    />
                </div>
                <div className={classes.leftArrowImgBox}>
                    <img src={leftArrow} onClick={moveBasicPage} alt='' />
                </div>
            </Wrapper>
        </>
    );
};

export default CustomerRegisterDetail;
