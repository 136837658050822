import { Button, Card, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import axios, { AxiosError } from 'axios';
import React, { useCallback, useState } from 'react';
import { useAsync } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { useForm } from '../../hooks/useForm';
import TempoStore from '../../store/TempoStore';
import Api, { ErrorMessages, ErrorResponse, StaffFormType } from '../../utils/Api';
import { StaffEntity } from '../@types/mysqlEntities';
import ErrorMessage from '../base/feedback/ErrorMessage';
import { colors } from '../const/Styles';
import CloseModalButton from './input/CloseModalButton';
import StaffRegisterImageForm from './StaffRegisterImageForm';
import StaffUpdateCard from './StaffUpdateCard';

const useStyles = makeStyles(() =>
    createStyles({
        previousPicture: {
            width: 112,
            height: 112,
        },
        gridItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        registerButtonBox: {
            display: 'flex',
            justifyContent: 'center',
            margin: 10,
        },
        registerButton: {
            display: 'flex',
            justifyContent: 'center',
            margin: 10,
            width: 445,
            backgroundColor: `${colors.footerIconSelected}`,
            color: `${colors.black}`,
            fontWeight: 'bold',
            fontSize: 17,
        },
        title: {
            color: `${colors.footerIconSelected}`,
            fontSize: 37,
            fontWeight: 'bold',
        },
        titleBox: {
            display: 'flex',
            width: '100%',
            margin: '45px 22px',
        },
        titleWrap: {
            width: '75%',
        },
        closeBtnBox: {
            position: 'absolute',
            top: 20,
            right: 40,
        },
        main: {
            position: 'relative',
            width: '100%',
            minWidth: 650,
            border: `1px solid ${colors.black}`,
            margin: 'auto',
        },
        profileImgBox: {
            width: '25%',
        },
    }),
);

export interface CreateStaffFormLocationState {
    staff?: StaffEntity;
}

type Props = {
    isOpended: boolean;
    onClose: () => void;
    staff: StaffEntity;
    onDelete: () => void
};

const UpdateStaffForm: React.FC<Props> = ({ isOpended, onClose, staff, onDelete }) => {
    const classes = useStyles();
    const [form, formDispacher] = useForm<StaffFormType>({
        ...staff,
        is_deleted: typeof staff.deleted_at === 'string'
    });

    const oldProfilePictureAsync: AsyncState<string> = useAsync(async () => {
        const profile_picture_filepath = staff.profile_picture_filepath;
        if (!profile_picture_filepath)
            return `${import.meta.env.BASE_URL}sample_account.png`;
        const { presignedUrl } =
            await Api.getS3SignedUrlForDownloadStaffPicture(
                profile_picture_filepath,
            );
        return presignedUrl;
    });
    const [profilePicture, setProfilePicture] = useState<string>();
    const [imageFile, setImageFile] = useState<File>();
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const imgFile = event.target.files[0];
        setImageFile(imgFile);
        setProfilePicture(window.URL.createObjectURL(imgFile));
    };

    const [errorProps, setErrorProps] =
        React.useState<ErrorMessages>();

    const onClickRegister = useCallback(async () => {
        let filename: string;
        try {
            const response = await Api.updateStaff({ ...form, profile_picture_filename: imageFile?.name ?? '' });
            filename = response.profile_picture_filename || '';
        } catch (err) {
            if (!axios.isAxiosError(err)) {
                throw err;
            }
            const { response } = err as AxiosError<ErrorResponse>;
            setErrorProps(response?.data.message ?? {});
            return;
        }

        try {
            if (imageFile) {
                const presignedUrl =
                    await Api.getS3SignedUrlForUploadStaffPicture(filename);
                await Api.uploadToS3(presignedUrl.presignedUrl, imageFile);
            }
            // ticket:256で改修予定
            // FIXME: ticket236: ここで TempoStore.fetchAllStaffs() を叩くと画像アップロード後の縮小処理が未完なのでほぼ失敗する。
            await TempoStore.fetchAllStaffs();
        } catch (err) {
            if (axios.isAxiosError(err)) {
                console.log(err);
            }
            throw err;
        }
        onClose();
    }, [form, imageFile, onClose]);

    return (
        <Card className={classes.main}>
            <Grid container className={classes.titleBox}>
                <Grid className={classes.profileImgBox}>
                    <StaffRegisterImageForm
                        img={profilePicture ?? oldProfilePictureAsync.value}
                        handleFileChange={handleFileChange}
                    />
                    <ErrorMessage field='profile_picture_filename' errors={errorProps} />
                </Grid>
                <div className={classes.titleWrap}>
                    <p className={classes.title}>プロフィール編集</p>
                </div>
                <CloseModalButton
                    isOpended={isOpended}
                    onClose={onClose}
                />
                <Grid item xs={10}></Grid>
            </Grid>
            <StaffUpdateCard
                data={form}
                dispatcher={formDispacher}
                errorProps={errorProps}
                onDelete={onDelete}
            />
            <div className={classes.registerButtonBox}>
                <Button
                    onClick={onClickRegister}
                    className={classes.registerButton}
                >
                    更新
                </Button>
            </div>
        </Card>
    );
}

export default UpdateStaffForm;
