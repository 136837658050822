import React, {
    useState,
    useCallback,
    useMemo,
    useRef,
    useEffect,
} from 'react';
import {
    Container,
    Box,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    Button,
    Modal,
    Slider,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import StopIcon from '@material-ui/icons/Stop';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { observer } from 'mobx-react-lite';

import useVideoJS from '../../hooks/useVideoJs';
import { VideoMovie, VideoBlob } from './PlayingVideoCard';
import RecordingStore from '../../store/RecordingStore';
import DominantButton from '../form/DominantButton';
import VideoEditorDouble from './VideoEditorDouble';
import { getVideoFormat } from '../../utils/utilityFuncs';

const useStyles = makeStyles(() =>
    createStyles({
        doubleVideoContainer: {
            padding: 5,
            display: 'flex',
            justifyContent: 'space-around',
        },
        videoBox: {
            position: 'relative',
        },
        canvas: {
            position: 'absolute',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            padding: '10px 0 15px',
        },
        videoButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginLeft: 275,
            marginRight: 3,
        },
        videoButton: {
            padding: 6,
        },
        playBackRateForm: {
            margin: 'auto 0',
        },
        thumbnailBox: (props: { width: number }) => ({
            padding: '5px 0',
            display: 'flex',
            justifyContent: 'space-between',
            width: props.width,
        }),
        thumbnail: {
            width: 35,
            height: 35,
        },
        imgButton: {
            padding: 0,
            minWidth: 0,
        },
        sideButton: {
            width: 105,
            height: 40,
            margin: 'auto 0 auto 3px',
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

interface Props {
    videoSrcRight: VideoMovie | VideoBlob;
    videoSrcLeft: VideoMovie | VideoBlob;
    height: number;
    width: number;
    scaleRatio: number;
    customerId: string | undefined;
    onClickToVideoList: () => void;
}

const PlayingVideoDouble = observer((props: Props): React.ReactElement => {
    const {
        videoSrcRight,
        videoSrcLeft,
        height,
        width,
        scaleRatio,
        customerId,
        onClickToVideoList,
    } = props;
    const classes = useStyles({ width });

    const srcLeft = useMemo(
        () =>
            videoSrcLeft.type === 'movie'
                ? videoSrcLeft.filepath
                : videoSrcLeft.videoSrc,
        [videoSrcLeft],
    );
    const srcRight = useMemo(
        () =>
            videoSrcRight.type === 'movie'
                ? videoSrcRight.filepath
                : videoSrcRight.videoSrc,
        [videoSrcRight],
    );
    const {
        Video: VideoLeft,
        player: playerLeft,
        thumbnails: thumbnailsLeft,
        actions: actionsLeft,
    } = useVideoJS({
        sources: [{ src: srcLeft, type: getVideoFormat() }],
        // poster: `${import.meta.env.BASE_URL}/sample_lesson.jpeg`,
        // ...baseOptions,
        height: height,
        width: width,
    });
    const {
        Video: VideoRight,
        player: playerRight,
        thumbnails: thumbnailsRight,
        actions: actionsRight,
    } = useVideoJS({
        sources: [{ src: srcRight, type: getVideoFormat() }],
        // poster: `${import.meta.env.BASE_URL}/sample_lesson.jpeg`,
        // ...baseOptions,
        height: height,
        width: width,
    });

    const [playing, setPlaying] = useState(false);

    const startBoth = useCallback(() => {
        if (playerLeft?.paused() && playerRight?.paused()) {
            playerLeft?.play();
            playerRight?.play();
            setPlaying(true);
        } else {
            playerLeft?.pause();
            playerRight?.pause();
            setPlaying(false);
        }
    }, [playerLeft, playerRight]);

    const onClickScreen = useCallback(() => {
        playerLeft?.paused() && playerRight?.paused()
            ? setPlaying(false)
            : setPlaying(true);
    }, [playerLeft, playerRight]);

    const onChangePlayBackRate = useCallback(
        (rate: number) => {
            actionsLeft.onChangePlayBackRate(rate);
            actionsRight.onChangePlayBackRate(rate);
        },
        [actionsLeft, actionsRight],
    );
    const skipPlus = useCallback(() => {
        actionsLeft.skipPlus();
        actionsRight.skipPlus();
    }, [actionsLeft, actionsRight]);
    const skipMinus = useCallback(() => {
        actionsLeft.skipMinus();
        actionsRight.skipMinus();
    }, [actionsLeft, actionsRight]);

    const toolImageLeft = RecordingStore.getToolImage(videoSrcLeft);
    const toolImageRight = RecordingStore.getToolImage(videoSrcRight);
    const canvasRefLeft = useRef<HTMLCanvasElement>(null);
    const canvasRefRight = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        const canvasLeft = canvasRefLeft.current;
        const canvasRight = canvasRefRight.current;
        const scaledHeight = height * scaleRatio;
        if (canvasLeft) {
            const ctxLeft = canvasLeft.getContext('2d');
            if (ctxLeft) {
                const imgLeft = new Image();
                imgLeft.src = toolImageLeft;
                imgLeft.onload = () => {
                    ctxLeft.drawImage(
                        imgLeft,
                        0,
                        0,
                        imgLeft.width,
                        imgLeft.height,
                        0,
                        (height - scaledHeight) / 2,
                        width,
                        scaledHeight,
                    );
                };
            }
        }
        if (canvasRight) {
            const ctxRight = canvasRight.getContext('2d');
            if (ctxRight) {
                const imgRight = new Image();
                imgRight.src = toolImageRight;
                imgRight.onload = () => {
                    ctxRight.drawImage(
                        imgRight,
                        0,
                        0,
                        imgRight.width,
                        imgRight.height,
                        0,
                        (height - scaledHeight) / 2,
                        width,
                        scaledHeight,
                    );
                };
            }
        }
    }, [height, scaleRatio, toolImageLeft, toolImageRight, width]);

    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const [currentPlayingTimeLeft, setCurrentPlayingTimeLeft] = useState(0);
    const [currentPlayingTimeRight, setCurrentPlayingTimeRight] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentPlayingTimeLeft(playerLeft?.currentTime() ?? 0);
        }, 10);
        return () => {
            clearInterval(intervalId);
        };
    }, [playerLeft, setCurrentPlayingTimeLeft]);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentPlayingTimeRight(playerRight?.currentTime() ?? 0);
        }, 10);
        return () => {
            clearInterval(intervalId);
        };
    }, [playerRight, setCurrentPlayingTimeRight]);
    const onLeftSeekbarChange = useCallback(
        (event, second) => {
            actionsLeft.skip(second);
        },
        [actionsLeft],
    );
    const onRightSeekbarChange = useCallback(
        (event, second) => {
            actionsRight.skip(second);
        },
        [actionsRight],
    );

    // 前回ページのスクロール量をリセットし、要素の表示と実際の当たり判定のずれを解消するハック
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <React.Fragment>
            <Container className={classes.doubleVideoContainer}>
                <Box className={classes.videoBox}>
                    <canvas
                        style={{ zIndex: 1 }}
                        ref={canvasRefLeft}
                        className={classes.canvas}
                        id='canvas'
                        height={`${height}px`}
                        width={`${width}px`}
                    />
                    <VideoLeft onClick={onClickScreen} />
                    <Box className={classes.thumbnailBox}>
                        {thumbnailsLeft?.map(([thumbnail, currentTime]) => {
                            return (
                                <Button
                                    key={currentTime}
                                    onClick={() => {
                                        actionsLeft.skip(currentTime);
                                        setCurrentPlayingTimeLeft(currentTime);
                                    }}
                                    className={classes.imgButton}
                                >
                                    <img
                                        src={thumbnail}
                                        className={classes.thumbnail}
                                    />
                                </Button>
                            );
                        })}
                    </Box>
                    <Box style={{ width: '80%', margin: '0 auto' }}>
                        <Slider
                            defaultValue={0}
                            min={0}
                            max={playerLeft?.duration()}
                            value={currentPlayingTimeLeft}
                            onChange={onLeftSeekbarChange}
                        />
                    </Box>
                </Box>
                <Box className={classes.videoBox}>
                    <canvas
                        style={{ zIndex: 1 }}
                        ref={canvasRefRight}
                        className={classes.canvas}
                        id='canvas'
                        height={`${height}px`}
                        width={`${width}px`}
                    />
                    <VideoRight onClick={onClickScreen} />
                    <Box className={classes.thumbnailBox}>
                        {thumbnailsRight?.map(([thumbnail, currentTime]) => {
                            return (
                                <Button
                                    key={currentTime}
                                    onClick={() => {
                                        actionsRight.skip(currentTime);
                                        setCurrentPlayingTimeRight(currentTime);
                                    }}
                                    className={classes.imgButton}
                                >
                                    <img
                                        src={thumbnail}
                                        className={classes.thumbnail}
                                    />
                                </Button>
                            );
                        })}
                    </Box>
                    <Box style={{ width: '80%', margin: '0 auto' }}>
                        <Slider
                            defaultValue={0}
                            min={0}
                            max={playerRight?.duration()}
                            value={currentPlayingTimeRight}
                            onChange={onRightSeekbarChange}
                        />
                    </Box>
                </Box>
            </Container>
            <Box className={classes.buttonContainer}>
                <Box className={classes.videoButtonContainer}>
                    <IconButton
                        onClick={skipMinus}
                        className={classes.videoButton}
                    >
                        <ChevronLeftIcon color='action' fontSize='large' />
                    </IconButton>
                    <IconButton
                        onClick={startBoth}
                        className={classes.videoButton}
                    >
                        {playing ? (
                            <StopIcon color='action' fontSize='large' />
                        ) : (
                            <PlayArrowRoundedIcon
                                color='action'
                                fontSize='large'
                            />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={skipPlus}
                        className={classes.videoButton}
                    >
                        <ChevronRightIcon color='action' fontSize='large' />
                    </IconButton>
                    <FormControl className={classes.playBackRateForm}>
                        <Select
                            variant='outlined'
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            defaultValue={1}
                            onChange={(e) =>
                                onChangePlayBackRate(e.target.value as number)
                            }
                        >
                            <MenuItem value={1.5}>x1.5</MenuItem>
                            <MenuItem value={1}>x1</MenuItem>
                            <MenuItem value={0.5}>x0.5</MenuItem>
                            <MenuItem value={0.1}>x0.1</MenuItem>
                            <MenuItem value={0.0625}>x0.0625</MenuItem>
                        </Select>
                    </FormControl>
                    <DominantButton
                        className={classes.sideButton}
                        onClick={handleOpen}
                    >
                        各種ツール
                    </DominantButton>
                    {customerId !== null && (
                        <DominantButton
                            onClick={onClickToVideoList}
                            className={classes.sideButton}
                        >
                            動画一覧へ
                        </DominantButton>
                    )}
                    <Modal
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                    >
                        <VideoEditorDouble
                            videoSrc={[videoSrcLeft, videoSrcRight]}
                            height={height}
                            width={width}
                            scaleRatio={scaleRatio}
                        />
                    </Modal>
                </Box>
            </Box>
        </React.Fragment>
    );
});

export default PlayingVideoDouble;
