import React from 'react';
import ReactDOM from 'react-dom';

import AppContainer from './routing/AppContainer';

import * as Sentry from '@sentry/react';

if (import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_SENTRY_AUTH_TOKEN) {
    Sentry?.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [],
    });
}

const App: React.FC = () => {
    return <AppContainer />;
};

ReactDOM.render(<App />, document.getElementById('root'));
