import React, { ChangeEvent, Dispatch, FormEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CustomerAttributeTabContext } from '../../../contexts/CustomerAttributeTabContext';
import { useStore } from '../../../contexts/StoreContext';
import { FormReducerAction } from '../../../hooks/useForm';
import { CustomerDisplay } from '../../../store/LessonStore';
import { PersonalityType } from '../../const/Personality';
import { DominantHandType } from '../../const/DominantHands';
import InputErrorMessage from '../../base/feedback/InputErrorMessage';
import { CUSTOMER_ATTRIBUTE_BASIC } from '../../const/CustomerAttributeTabProperties';
import { GenderKeyType } from '../../const/Gender';
import InlineFormLayout from '../layout/InlineFormLayout';
import AttributedInput from './AttributedInput';
import BaseInput from './BaseInput';
import BaseSelect from './BaseSelect';
import BirthdayInputGroup from './BirthdayInputGroup';
import DominantHandSelector from './DominantHandSelector';
import GenderSelector from './GenderSelector';
import PersonalitySelector from './PersonalitySelector';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import AuthStore from '../../../store/AuthStore';
import { LoginKinds } from '../../const/LoginKinds';
import { colors } from '../../const/Styles';
import Api from '../../../utils/Api';
import { Routes } from '../../const/Routes';

type VisibleProps = {
    shouldVisible: boolean;
};

const Container = styled.div<VisibleProps>`
    display: ${({ shouldVisible }) => (shouldVisible ? 'flex' : 'none')};
    flex-direction: column;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    > * {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
`;

const ActionRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 8px 0 0 14em;
    box-sizing: border-box;
`;

const ActionButton = styled.button`
    display: block;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: ${colors.black};
    box-sizing: border-box;
    padding: 5px 15px;
    border: none;
    outline: none;
    background-color: ${colors.lighterGray};
    border-radius: 4px;
`;

type Props = {
    values: CustomerDisplay;
    dispacher: Dispatch<FormReducerAction<CustomerDisplay>>;
    errors: Map<string, string[]>;
};

const BasicForm: React.FC<Props> = ({ values, dispacher, errors }) => {
    const { tempoStore, lessonStore } = useStore();
    const { allLessons } = tempoStore;
    const handleChangeLesson = (evt: FormEvent<HTMLSelectElement>) => {
        dispacher({
            value: { lesson_master_id: Number(evt.currentTarget.value) },
        });
    };
    const handleInputUserName = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({
            value: {
                user_name: evt.currentTarget.value.replace(
                    /[\u3000\x20\t]/,
                    '',
                ),
            },
        });
    };
    const handleInputEmail = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({
            value: {
                email: evt.currentTarget.value.replace(/[\u3000\x20\t]/, ''),
            },
        });
    };
    const handleInputPassword = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({
            value: {
                password: evt.currentTarget.value.replace(/[\u3000\x20\t]/, ''),
            },
        });
    };
    const handleInputFirstName = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({
            value: {
                name_sei: evt.currentTarget.value.replace(/[\u3000\x20\t]/, ''),
            },
        });
    };
    const handleInputLastName = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({
            value: {
                name_mei: evt.currentTarget.value.replace(/[\u3000\x20\t]/, ''),
            },
        });
    };
    const handleInputPhoneticFirstName = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({
            value: {
                name_sei_kana: evt.currentTarget.value.replace(
                    /[\u3000\x20\t]/,
                    '',
                ),
            },
        });
    };
    const handleInputPhoneticLastName = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({
            value: {
                name_mei_kana: evt.currentTarget.value.replace(
                    /[\u3000\x20\t]/,
                    '',
                ),
            },
        });
    };
    const handleInputBirthday = (value: string) => {
        dispacher({ value: { date_of_birth: value } });
    };
    const handleChangeGender = (value: GenderKeyType) => {
        dispacher({ value: { sex: value } });
    };
    const handleInputHeight = (evt: FormEvent<HTMLInputElement>) => {
        const { value } = evt.currentTarget;
        dispacher({ value: { height: value ? Number(value) : undefined } });
    };
    const handleInputGripLeft = (evt: FormEvent<HTMLInputElement>) => {
        const { value } = evt.currentTarget;
        dispacher({ value: { grip_left: value ? Number(value) : undefined } });
    };
    const handleInputGripRight = (evt: FormEvent<HTMLInputElement>) => {
        const { value } = evt.currentTarget;
        dispacher({ value: { grip_right: value ? Number(value) : undefined } });
    };
    const handleChangeDominantHand = (value: DominantHandType) => {
        dispacher({ value: { dominant_hand: value } });
    };
    const handleChangePersonality1 = (value: PersonalityType) => {
        dispacher({ value: { personality_1: value } });
    };
    const handleChangePersonality2 = (value: PersonalityType) => {
        dispacher({ value: { personality_2: value } });
    };
    const handleInputPurposeBegin = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { purpose_begin: evt.currentTarget.value } });
    };
    const handleInputTarget = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { target: evt.currentTarget.value } });
    };
    const handleInputRegisteredAt = (evt: FormEvent<HTMLInputElement>) => {
        dispacher({ value: { registered_at: evt.currentTarget.value } });
    };
    const { selected } = useContext(CustomerAttributeTabContext);
    const [isVisiblePassword, setIsVisiblePassword] = useState(false);
    const handleVisiblePassword = () => {
        setIsVisiblePassword(!isVisiblePassword);
    };
    const isLoginWithEmailAndPassword =
        AuthStore.loginKind === LoginKinds.LOGIN_WITH_MAIL_ADDRESS_AND_PASSWORD;

    const handleChangeDeleteFlg = (
        _: ChangeEvent<HTMLInputElement>
        , checked: boolean
    ) => {
        dispacher({ value: { is_deleted: !checked } })
    }

    const router = useHistory();
    const handleClickDeleteButton = async () => {
        if (!confirm('このメンバーを削除します。\n削除してもよろしいですか？')) {
            return;
        }

        if (!confirm('削除するとすべてのデータが削除されます。\nそれでもよろしいですか？')) {
            return;
        }

        try {
            // 物理削除
            await Api.deleteCustomer(values.id);
            // 削除後にリロード
            await lessonStore.fetchAllCustomers(AuthStore.tempoId);

            alert('メンバーを削除しました');
            router.push(Routes.LIBRARY_LIST);
        } catch (error: unknown) {
            alert('メンバーの削除に失敗しました。\n再度時間をおいて実行してください。');
            console.error(error);
            throw error;
        }
    }

    return (
        <Container shouldVisible={selected === CUSTOMER_ATTRIBUTE_BASIC}>
            <InlineFormLayout title='レッスン名' required>
                <InputErrorMessage
                    fields={['lesson_master_id']}
                    errors={errors}
                />
                <BaseSelect
                    className='lg'
                    value={String(values.lesson_master_id)}
                    onChange={handleChangeLesson}
                >
                    {allLessons.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </BaseSelect>
            </InlineFormLayout>
            <InlineFormLayout title='会員ID'>
                <BaseInput
                    className='sm'
                    type='text'
                    value={values.user_name ?? ''}
                    onInput={handleInputUserName}
                />
                <InputErrorMessage fields={['user_name']} errors={errors} />
            </InlineFormLayout>
            <InlineFormLayout
                title='登録メールアドレス'
                required={isLoginWithEmailAndPassword}
            >
                <InputErrorMessage fields={['email']} errors={errors} />
                <BaseInput
                    className='lg'
                    type='text'
                    value={values.email ?? ''}
                    onInput={handleInputEmail}
                />
            </InlineFormLayout>
            <InlineFormLayout
                title='パスワード'
                required={isLoginWithEmailAndPassword}
            >
                <InputErrorMessage fields={['password']} errors={errors} />
                <Row>
                    <BaseInput
                        className='lg'
                        type={isVisiblePassword ? 'text' : 'password'}
                        value={values.password ?? ''}
                        onInput={handleInputPassword}
                    />
                    <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleVisiblePassword}
                    >
                        {isVisiblePassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </Row>
                {values.has_password
                    ? 'パスワード設定済です'
                    : 'パスワードが設定されていません'}
            </InlineFormLayout>
            <InlineFormLayout title='氏名' required>
                <InputErrorMessage
                    fields={['name_sei', 'name_mei']}
                    errors={errors}
                />
                <Row>
                    <AttributedInput
                        className='lg'
                        type='text'
                        title='姓'
                        value={values.name_sei}
                        onInput={handleInputFirstName}
                    />
                    <AttributedInput
                        className='lg'
                        type='text'
                        title='名'
                        value={values.name_mei}
                        onInput={handleInputLastName}
                    />
                </Row>
            </InlineFormLayout>
            <InlineFormLayout title='ふりがな' required>
                <InputErrorMessage
                    fields={['name_sei_kana', 'name_mei_kana']}
                    errors={errors}
                />
                <Row>
                    <AttributedInput
                        className='lg'
                        type='text'
                        title='せい'
                        value={values.name_sei_kana}
                        onInput={handleInputPhoneticFirstName}
                    />
                    <AttributedInput
                        className='lg'
                        type='text'
                        title='めい'
                        value={values.name_mei_kana}
                        onInput={handleInputPhoneticLastName}
                    />
                </Row>
            </InlineFormLayout>
            <InlineFormLayout title='生年月日'>
                <InputErrorMessage fields={['date_of_birth']} errors={errors} />
                <BirthdayInputGroup
                    value={values.date_of_birth}
                    onChange={handleInputBirthday}
                />
            </InlineFormLayout>
            <InlineFormLayout title='性別'>
                <GenderSelector
                    selected={values.sex}
                    onChange={handleChangeGender}
                />
            </InlineFormLayout>
            <InlineFormLayout title='身長(cm)'>
                <InputErrorMessage fields={['height']} errors={errors} />
                <BaseInput
                    className='sm'
                    type='number'
                    value={values.height ?? ''}
                    onInput={handleInputHeight}
                />
            </InlineFormLayout>
            <InlineFormLayout title='握力(kg)'>
                <InputErrorMessage
                    fields={['grip_left', 'grip_right']}
                    errors={errors}
                />
                <Row>
                    <AttributedInput
                        className='lg'
                        type='number'
                        title='左'
                        value={values.grip_left ?? ''}
                        onInput={handleInputGripLeft}
                    />
                    <AttributedInput
                        className='lg'
                        type='number'
                        title='右'
                        value={values.grip_right ?? ''}
                        onInput={handleInputGripRight}
                    />
                </Row>
            </InlineFormLayout>
            <InlineFormLayout title='利き手'>
                <DominantHandSelector
                    selected={values.dominant_hand}
                    onChange={handleChangeDominantHand}
                />
            </InlineFormLayout>
            <InlineFormLayout title='学習スタイル1'>
                <PersonalitySelector
                    selected={values.personality_1}
                    onChange={handleChangePersonality1}
                />
            </InlineFormLayout>
            <InlineFormLayout title='学習スタイル2'>
                <PersonalitySelector
                    selected={values.personality_2}
                    onChange={handleChangePersonality2}
                />
            </InlineFormLayout>
            <InlineFormLayout title='受講理由'>
                <InputErrorMessage fields={['purpose_begin']} errors={errors} />
                <BaseInput
                    className='lg'
                    type='text'
                    value={values.purpose_begin}
                    onInput={handleInputPurposeBegin}
                />
            </InlineFormLayout>
            <InlineFormLayout title='目標設定'>
                <InputErrorMessage fields={['target']} errors={errors} />
                <BaseInput
                    className='lg'
                    type='text'
                    value={values.target ?? undefined}
                    onInput={handleInputTarget}
                />
            </InlineFormLayout>
            <InlineFormLayout title='登録日'>
                <InputErrorMessage fields={['registered_at']} errors={errors} />
                <BaseInput
                    className='md'
                    type='date'
                    value={values.registered_at}
                    onInput={handleInputRegisteredAt}
                />
            </InlineFormLayout>
            <ActionRow>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={handleChangeDeleteFlg}
                            checked={!values.is_deleted}
                        />
                    }
                    label='入会'
                />
                <ActionButton onClick={handleClickDeleteButton}>即時削除</ActionButton>
            </ActionRow>
        </Container>
    );
};

export default BasicForm;
